<script>
export default {
  data() {
    return {
      stripeScriptStatus: 'loading',
      stripe: null,
      card: null,
    };
  },
  created() {
    this.loadStripeScript();
  },
  methods: {
    loadStripeScript() {
      this.stripeScriptStatus = 'loading';

      const stripeScript = document.getElementById('stripe_script');

      if (stripeScript) {
        document.body.removeChild(stripeScript);
      }

      const script = document.createElement('script');
      script.id = 'stripe_script';
      script.src = 'https://js.stripe.com/v3/';
      script.onerror = () => {
        this.stripeScriptStatus = 'error';
      };
      script.onload = () => {
        this.stripeScriptStatus = 'loaded';
        this.createCreditCardElement();
      };
      document.body.appendChild(script);
    },
    createCreditCardElement() {
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      const elements = this.stripe.elements();
      const style = {};

      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        style.base = { fontSize: '16px' };
      }

      this.card = elements.create('card', { style });

      this.card.addEventListener('change', (event) => {
        const cardErrors = document.getElementById('card-errors');
        if (event.error) {
          cardErrors.classList.add('alert');
          cardErrors.textContent = event.error.message;
        } else {
          cardErrors.classList.remove('alert');
          cardErrors.textContent = '';
        }
      });

      this.$nextTick(() => {
        this.card.mount('#card-element');
      });
    },
  },
};
</script>
