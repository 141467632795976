<template>
  <div
    class="program card rounded-lg shadow-sm mb-4 p-3"
    :class="{'active' : active }"
  >
    <p class="h4">
      {{ program.title }}
    </p>

    <b-form-radio
      v-for="payment_method of program.payment_methods"
      :key="`program${payment_method.id}`"
      :value="`${program.id}-${payment_method.id}`"
      class="ml-2"
    >
      <strong>{{ formatPrice(payment_method.price) }}</strong>
      ({{ payment_method.title }})
    </b-form-radio>
  </div>
</template>

<script>
export default {
  name: 'ProgramRadioCard',
  props: {
    program: { type: Object, required: true },
    active: { type: Boolean, default: false },
  },
  methods: {
    formatPrice(price) {
      return `$${price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.00', '')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card.active {
  background-color: #87cdfc1f;
  border-color: #87cefc !important;
}
</style>
