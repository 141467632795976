<template>
  <transition
    name="slide"
    v-bind="$attrs"
    v-on="hooks"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: 'vertical',
    },
    duration: {
      type: Number,
      default: 400,
    },
  },
  computed: {
    hooks() {
      return {
        beforeEnter: this.beforeEnter,
        afterEnter: this.afterEnter,
        beforeLeave: this.beforeLeave,
        afterLeave: this.afterLeave,
      };
    },
    widthOrHeight() {
      return this.direction === 'vertical' ? 'height' : 'width';
    },
  },
  methods: {
    beforeEnter(el) {
      el.style[this.widthOrHeight] = '0';
      el.style.transitionDuration = `${this.duration}ms`;
      el.style.transitionProperty = this.widthOrHeight;

      this.$nextTick(() => {
        if (this.widthOrHeight === 'height') {
          el.style.height = `${el.scrollHeight}px`;
        } else {
          el.style.width = `${el.scrollWidth}px`;
        }
      });
    },
    afterEnter(el) {
      el.style[this.widthOrHeight] = '';
      el.style.transitionDuration = '';
      el.style.transitionProperty = '';
    },
    beforeLeave(el) {
      if (this.widthOrHeight === 'height') {
        el.style.height = `${el.scrollHeight}px`;
      } else {
        el.style.width = `${el.scrollWidth}px`;
      }

      el.style.transitionDuration = `${this.duration}ms`;
      el.style.transitionProperty = this.widthOrHeight;

      setTimeout(() => {
        el.style[this.widthOrHeight] = '0';
      }, 50);
    },
    afterLeave(el) {
      el.style[this.widthOrHeight] = '';
      el.style.transitionDuration = '';
      el.style.transitionProperty = '';
    },
  },
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  overflow: hidden;
  transition-timing-function: ease-in-out;
}
</style>
